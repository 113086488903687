<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  // head: {
  //   link: [
  //     {
  //       rel: 'icon',
  //       href: require('@/assets/logo.ico')
  //     }
  //   ]
  // },
  components: {},
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="sass">
@import "@/styles/main"
</style>
